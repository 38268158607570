import React from 'react';
import { Form, Button, Input } from 'antd';
import { FormInput } from '../FormInput/FormInput';
import './ContactForm.less';

export const ContactForm = () => {
  const url = `${process.env.STRAPI_API_URL}/api/contact-form`;

  const onFinish = async (values: [string | number]) => {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(values),
    });
    const form = document.getElementById('basic');
    if (form) {
      form.reset();
      alert('Twoja wiadomość została wysłana');
    }
  };

  return (
    <Form onFinish={onFinish} name="basic" className="rectangle-form">
      <Form.Item
        className="item"
        name="name"
        rules={[{ required: true, message: 'Imię jest wymagane!' }]}
      >
        <FormInput placeholder={'Imię'} />
      </Form.Item>
      <Form.Item
        className="item"
        name="surname"
        rules={[{ required: true, message: 'Nazwisko jest wymagane!' }]}
      >
        <FormInput placeholder={'Nazwisko'} />
      </Form.Item>
      <Form.Item
        className="item"
        name="email"
        rules={[
          { required: true, type: 'email', message: 'E-mail jest wymagany!' },
        ]}
      >
        <FormInput placeholder={'E-mail'} />
      </Form.Item>
      <Form.Item
        className="item"
        name="topic"
        rules={[{ required: true, message: 'Temat jest wymagany!' }]}
      >
        <FormInput placeholder={'Temat'} />
      </Form.Item>
      <Form.Item name="message" className="text-area item">
        <Input.TextArea
          placeholder={'Treść wiadomości'}
          className="text-area"
        />
      </Form.Item>
      <Button htmlType="submit" className="form-submit item">
        Wyślij wiadomość
      </Button>
    </Form>
  );
};
