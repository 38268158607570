import React from 'react';
import { Layout } from '../components/Layout/Layout';
import { Typography, Divider, Row, Col } from 'antd';
import './contact.less';
import { ContactForm } from '../components/ContactForm/ContactForm';
import { ContactInformation } from '../components/ContactInformation/ContactInformation';
import { PageProps, graphql } from 'gatsby';

type ContactFormContent = {
  strapiContactFormContent: {
    strapi_id: number;
    number: string;
    email: string;
    company: string;
    street: string;
    postalcodeandcity: string;
  };
};

const Contact = ({
  data: { strapiContactFormContent },
}: PageProps<ContactFormContent>) => {
  const { Title, Paragraph } = Typography;
  const number = strapiContactFormContent?.number;
  const email = strapiContactFormContent?.email;
  const street = strapiContactFormContent?.street;
  const city = strapiContactFormContent?.postalcodeandcity;
  const company = strapiContactFormContent?.company;

  return (
    <Layout>
      <div className="page-contact">
        <Title level={3} className="contact-title">
          Jak możemy Ci pomóc?
        </Title>
        <Paragraph className="contact-subtitle">
          Napisz do nas! Nasz Zespół odpowie na wszystkie Twoje pytania.
        </Paragraph>
        <Row className="form-and-information-wrapper">
          <Col xs={24} sm={2} xxl={4}></Col>
          <Col xs={24} sm={11} xxl={8}>
            <ContactForm />
          </Col>
          <Col xs={24} sm={1} xxl={2}></Col>
          <Col xs={24} sm={10} xxl={10}>
            <ContactInformation
              number={number}
              email={email}
              street={street}
              city={city}
              company={company}
            />
          </Col>
        </Row>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query ($strapi_id: Int) {
    strapiContactFormContent(strapi_id: { eq: $strapi_id }) {
      strapi_id
      number
      email
      company
      postalcodeandcity
      street
    }
  }
`;

export default Contact;
