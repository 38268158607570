import React from 'react';
import { Typography, Divider, Button } from 'antd';
import {
  FacebookOutlined,
  InstagramOutlined,
  PhoneOutlined,
  HomeOutlined,
  MailOutlined,
} from '@ant-design/icons';
import './ContactInformation.less';

const Paragraph = Typography;

export const ContactInformation = data => {
  return (
    <div>
      <Divider className="information-divider top" />
      <div className="information-wrapper">
        <div className="information">
          <PhoneOutlined className="contact-icon" />
          <Paragraph className="contact-paragraph">{data.number} </Paragraph>
        </div>
        <div className="information">
          <MailOutlined className="contact-icon" />
          <Paragraph className="contact-paragraph"> {data.email} </Paragraph>
        </div>
        <div className="information">
          <HomeOutlined className="contact-icon" />
          <Paragraph className="contact-paragraph">
            {data.company}
            <br></br>
            {data.street}
            <br></br>
            {data.city}
          </Paragraph>
        </div>
      </div>
      <Divider className="information-divider" />
      <div className="buttons-wrapper">
        <div className="button">
          <Button
            href={'https://www.instagram.com/'}
            type="primary"
            className="contact-button"
            icon={<InstagramOutlined />}
          >
            <Paragraph className="button-contact-text">
              Napisz na Instagramie
            </Paragraph>
          </Button>
        </div>
        <div className="button">
          <Button
            href={'https://www.facebook.com/'}
            type="primary"
            className="contact-button"
            icon={<FacebookOutlined />}
          >
            <Paragraph className="button-contact-text">
              Napisz na Facebooku
            </Paragraph>
          </Button>
        </div>
      </div>
      <Divider className="information-divider down" />
    </div>
  );
};
